import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import { store } from "./store/store";

import axios from "axios";
import VueAxios from "vue-axios";
import Vuelidate from "vuelidate";

import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { routes } from "./routes";

Vue.filter("dateFormat", (date) => {
  const newDate = new Date(date);
  let months = [
    "Leden",
    "Únor",
    "Březen",
    "Duben",
    "Květen",
    "Červen",
    "Červenec",
    "Srpen",
    "Září",
    "Říjen",
    "Listopad",
    "Prosinec",
  ];
  // let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let days = [
    "Neděle",
    "Pondělí",
    "Úterý",
    "Středa",
    "Čtvrtek",
    "Pátek",
    "Sobota",
  ];

  let missingZero = "";

  if (newDate.getMinutes() == "0") {
    missingZero = "0";
  }

  return (
    days[newDate.getDay()] +
    ", " +
    newDate.getDate() +
    ". " +
    months[newDate.getMonth()] +
    ", " +
    newDate.getHours() +
    ":" +
    newDate.getMinutes() +
    missingZero
  );
});
Vue.use(Vuelidate);
Vue.use(VueAxios, axios);
Vue.use(VueRouter);
Vue.use(BootstrapVue);

const router = new VueRouter({
  mode: "history",
  routes,
});

new Vue({
  el: "#app",
  store,
  router,
  render: (h) => h(App),
});
