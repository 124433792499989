<template>
  <section id="container-main" class="align-items-center">
    <div class="row">
      <div class="col-12 py-4 background-orange">
        <h1 class="">Vyzkoušejte si Obživu s Průvodcem</h1>
      </div>
    </div>

    <div class="row mt-5" v-if="!formLoaded">
      <div class="col-12">
        <p class="success">SERVER SE PROBOUZÍ...VYDRŽTE PROSÍM PÁR SEKUND :)</p>
        <p><b-spinner variant="success" label="Spinning"></b-spinner></p>
      </div>
    </div>

    <div class="row" v-if="!formSent && formLoaded">
      <div class="col-12 col-lg-8">
        <form class="form my-5 pb-5" v-on:submit.prevent>
          <div class="row">
            <div class="col-12">
              <input
                type="email"
                class="form-control"
                placeholder="Váš e-mail"
                v-model="$v.customer.email.$model"
              />
              <div
                v-if="submitStatus == 'ERROR' && !$v.customer.email.required"
              >
                <p class="error">Vyplňte prosím e-mail.</p>
              </div>
            </div>
            <div class="col-12 mt-4">
              <input
                name="phone"
                type="phone"
                class="form-control"
                placeholder="Váš telefon"
                v-model="$v.customer.phone.$model"
              />
              <div
                v-if="submitStatus == 'ERROR' && !$v.customer.phone.required"
              >
                <p class="error">Vyplňte prosím Váš telefon.</p>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12">
              <input
                id="name"
                type="text"
                class="form-control"
                placeholder="Jméno a příjmení"
                v-model="$v.customer.name.$model"
              />
            </div>

            <div v-if="submitStatus == 'ERROR' && !$v.customer.name.required">
              <p class="error">Vyplňte prosím Vaše jméno.</p>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12">
              <textarea
                style="width: 100%"
                name="Text1"
                rows="3"
                v-model="customer.question"
                placeholder="Co vás na Obživě láká a co byste se rád/a dozvěděl/a?"
              ></textarea>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <h3 class="mt-4 text-center program">
                Vyberte si termín průvodcovského programu.
              </h3>
              <hr />
            </div>

            <div class="col-12">
              <div
                class="form-check my-2 p-0"
                v-for="slot in getSlots"
                :key="slot.id"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  :value="slot"
                  v-model="$v.customer.slotSelected.$model"
                  :id="slot.id"
                  :name="slot.id"
                />
                <label class="form-check-label p-3" v-bind:for="slot.id">{{
                  slot.start | dateFormat
                }}</label>
              </div>
            </div>
            <div
              class="col-12"
              v-if="
                submitStatus == 'ERROR' && !$v.customer.slotSelected.required
              "
            >
              <p class="error">Vyberte si termín průvodcovského programu.</p>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-10 col-lg-12 boarder-orange p-3">
              <input
                type="checkbox"
                name="gdprSouhlas"
                id="gdprSouhlas"
                v-model="$v.customer.gdprSouhlas.$model"
              />
              <label for="gdprSouhlas" class="gdprSouhlas">
                Souhlasím se zpracováním osobních údajů po dobu 1 roku jenom pro
                účely tohoto programu. (Svůj souhlas můžete kdykoliv odvolat
                písemne nebo e-mailem na spolek@obziva.cz. Všechny kontakty
                Spolku živá obŽIVA jsou dostupné na webových stránkách
                <a href="https://www.obziva.cz">obziva.cz</a>.)</label
              >
              <div
                v-if="
                  submitStatus == 'ERROR' && !$v.customer.gdprSouhlas.sameAs
                "
              >
                <p class="error">
                  Potvrďte prosím souhlas se zpracováním údajů.
                </p>
              </div>
            </div>
          </div>

          <div class="row my-4">
            <div class="col-10 col-lg-10" v-if="submitStatus != 'SENDING'">
              <div v-if="submitStatus == 'ERROR'">
                <p class="error">Chybí vyplnit pole.</p>
              </div>
              <button class="btn btn-block py-3" @click="postForm()">
                ODESLAT
              </button>
            </div>

            <div class="text-center" v-if="submitStatus == 'SENDING'">
              <p>ODESÍLÁNÍ..</p>
              <b-spinner variant="success" label="Spinning"></b-spinner>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- SUCCESS -->
    <div class="row my-5" v-else-if="formSent && !isError">
      <div class="col-10 col-lg-6">
        <p class="success">
          Všechno se povedlo. <br />
          Na e-mail jsme vám zaslali potvrzení rezervace prohlídky.
        </p>
      </div>
    </div>

    <!-- ERROR -->
    <div class="row my-5" v-else-if="formSent && isError">
      <div class="col-10 col-lg-6">
        <p class="serverError">
          Je nám to líto, ale někde se stala chyba. Napište nám prosím na e-mail
          pruvodce@obziva.cz a domluvíme termín prohlídky po e-mailu.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { required, email, sameAs } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      customer: {
        name: "",
        email: "",
        phone: "",
        question: "",
        gdprSouhlas: false,
        slotSelected: "",
      },
      submitStatus: null,
      formLoaded: false,
    };
  },
  computed: {
    getSlots() {
      return this.$store.getters.slotsAvailable;
    },
    isError() {
      return this.$store.getters.formError;
    },
    formSent() {
      return this.$store.getters.formSent;
    },
  },
  methods: {
    postForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "SENDING";
        this.$store.dispatch("postForm", this.customer).then((res) => {
          console.log(res);
        });
      }
    },
  },
  created() {
    this.$store.dispatch("getAvailableSlots").then((res) => {
      console.log("response received ");
      console.log(res);
      this.formLoaded = true;
    });
  },
  validations: {
    customer: {
      email: {
        required,
        email,
      },
      name: {
        required,
      },
      phone: {
        required,
      },
      gdprSouhlas: {
        sameAs: sameAs(() => true),
      },
      slotSelected: {
        required,
      },
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Varela+Round");

.boarder-orange {
  border: 2px solid #f89f8b;
}
.background-orange {
  background: #f89f8b;
}
h1 {
  text-align: center;
  color: white;
  font-family: "Varela" !important;
}

h3 {
  font-family: "Varela" !important;
  color: #484848;
}
.row {
  margin-left: 0px;
  margin-right: 0px;
  justify-content: center;
  align-items: center;
}

label {
  color: #484848;
  font-weight: 500;
}

.selected {
  background: #73cf03;
}

.vyzvednuti,
.souhlas {
  font-size: 0.8em;
  display: inline;
}

::placeholder {
  color: #667981 !important;
  font-weight: 400 !important;
}

.form-text {
  color: #667981;
}

.error {
  color: #c11733;
  font-size: 1.2m;
}

.serverError {
  color: #c11733;
  font-size: 1.5m;
}

.success {
  color: #73cf03;
  font-size: 1.5em;
}

.form-control {
  border: 2px solid #f89f8b;
}

textarea {
  border: 2px solid #f89f8b;
}

.gdprSouhlas {
  font-size: 0.8em;
  display: inline;
}

.btn {
  transition: box-shadow 0.2s;
  box-shadow: 0 0 9px rgba(10, 10, 10, 0.5);
  transition: box-shadow 0.2s;
  color: white;
  display: block;
  font-weight: bold;
  margin: 0 auto;
  background-color: #73cf03;
  border-radius: 0.5em;
  border: 3px solid #73cf03;
  text-decoration: none;
  font-size: 0.8em;
}

.btn:hover {
  box-shadow: 0 0 0px rgba(10, 10, 10, 0.5);
}

hr {
  border-color: #f89f8b;
  border-width: 5px;
  width: 50%;
}

input[type="radio"] {
  opacity: 0;
}

input[type="radio"] + label {
  width: 100%;
  background: #f89f8b;
  color: white;
  text-align: center;
  cursor: pointer;
}

input[type="radio"]:checked + label {
  width: 100%;
  color: white;
  background: #73cf03;
}
</style>