import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    slotsAvailable: [],
    formSent: false,
    formError: false,
  },

  getters: {
    slotsAvailable: (state) => {
      return state.slotsAvailable;
    },
    formSent: (state) => {
      return state.formSent;
    },
    formError: (state) => {
      return state.formError;
    },
  },
  mutations: {
    slotsAvailable: (state, payload) => {
      console.log("slotsAvailable " + payload);
      state.slotsAvailable = payload;
    },

    formError: (state, payload) => {
      console.log("form error");
      console.log(payload);
      state.formSent = true;
      state.formError = true;
    },
    formPosted: (state, payload) => {
      console.log("form posted");
      console.log(payload);
      state.formSent = true;
    },
  },
  actions: {
    async getAvailableSlots({ commit }) {
      const getEvents = await axios.get(
        "https://obziva-vojtechkrajsa.vercel.app/api/getEvents"
      );
      console.log("getEvents");
      console.log(getEvents.data.data);
      commit("slotsAvailable", getEvents.data.data);
    },

    async postForm({ commit }, payload) {
      console.log(payload);
      try {
        const postForm = await axios.post(
          "https://obziva-vojtechkrajsa.vercel.app/api/bookEvent",
          {
            payload,
          }
        );
        console.log("form success");
        commit("formPosted", postForm);
      } catch (error) {
        console.log("form error");
        commit("formError", error);
      }
    },
  },
});
